$(function () {
  /* オブジェクト定義 */
  var $window = $(window);
  var $header = $('#header');
  windowHeight($window, $header); // トップのイメージの縦幅調整

  /* ウィンドウ幅を変更した時 */
  $window.on('resize', function () {
    windowHeight($(this), $header); // トップのイメージの縦幅調整
  });
});

/* ウインドウの縦幅に合わせる用 */
function windowHeight ($w, $h) {
  var wh = $w.height();
  $h.height(wh);
}

// ふわっと
$(function () {
  $(window).on('load scroll', function () {
    $('.animation').each(function () {
      var target = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var height = $(window).height();
      if (scroll > target - height) {
        $(this).addClass('active');
      }
    });
  });
});
